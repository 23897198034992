import package_json from '../../package.json';

export enum Functions {
  referralProgram = 'referralProgram',
  supportChat = 'supportChat',
  appUpdate = 'appUpdate',
  productsCatalog = 'productsCatalog',
  webhooks = 'webhooks',
  cashback = 'cashback',
  registration = 'registration',
}

export const environment = {
  production: true,
  backendEndpoint: 'https://api.cashli.in/v2',
  cdnEndpoint: 'https://cdn.cashli.in/',
  version: package_json.version,
  appMetric: "4e2f5343-2912-46f5-acac-8d8588511f73",
  supportEndpoint: 'https://support.cashli.in',
  feature: {
    [Functions.referralProgram]: true, //https://gitlab.com/cashli/cashlisub/common-board/-/issues/85
    [Functions.supportChat]: true, //https://gitlab.com/cashli/cashlisub/common-board/-/issues/94
    [Functions.appUpdate]: true, //
    [Functions.productsCatalog]: true, // https://gitlab.com/cashli/cashlisub/common-board/-/issues/96
    [Functions.webhooks]: true, // https://gitlab.com/cashli/cashlisub/common-board/-/issues/107
    [Functions.cashback]: false, //https://gitlab.com/cashli/cashlisub/common-board/-/issues/71
    [Functions.registration]: false, //https://gitlab.com/cashli/cashlisub/common-board/-/issues/167
  }
};
