import { Injectable, NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  PreloadAllModules, Resolve,
  Router,
  RouterModule,
  RouterStateSnapshot,
  Routes
} from '@angular/router';
import { AuthService } from './services/auth/auth.service'
import { StorageService } from './services/storage/storage.service'
import { STORAGE_KEYS } from './services/storage/storage.constants'
import { Observable } from 'rxjs'
import { OnboardingService } from './services/onboarding/onboarding.service'
import { LoginService } from './services/login/login.service'

@Injectable({ providedIn: 'root' })
export class IntroResolver implements Resolve<any> {
  constructor (private storage: StorageService) {
  }

  resolve (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.storage.set(STORAGE_KEYS.IS_INTRO_SHOWN, true)
  }
}


@Injectable({ providedIn: 'root' })
export class AuthResolver implements CanActivate {
  constructor(private auth: AuthService) {}
  async canActivate (): Promise<boolean> {
    await this.auth.init()
    //console.debug('auth: ', this.auth.authSession?.authToken)
    return true
  }
}

const routes: Routes = [
  {
    path: 'intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule),
    resolve: {
      set: IntroResolver
    }
  },
  {
    path: 'onboarding',
    canActivate: [AuthResolver],
    loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    //canActivate: [AuthResolver, LoginService],
  },
  {
    path: 'main',
    canActivate: [AuthResolver, AuthService, OnboardingService],
    canActivateChild: [AuthService],
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule)
  },
  {
    path: 'bank',
    loadChildren: () => import('./pages/bank/bank.module').then(m => m.BankPageModule)
  },
  {
    path: 'video-catalog',
    loadChildren: () => import('./pages/video-catalog/video-catalog.module').then( m => m.VideoCatalogPageModule)
  },
  {
    path: 'user-menu',
    loadChildren: () => import('./pages/user-menu/user-menu.module').then( m => m.UserMenuPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./pages/support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule)
  },
  {
    path: 'webhook',
    loadChildren: () => import('./pages/webhook/webhook.module').then( m => m.WebhookPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
