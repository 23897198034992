import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateInfo } from '@capawesome/capacitor-app-update';
import { environment } from '../../../environments/environment'
import { BehaviorSubject, catchError, Observable, of, tap } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { fromPromise } from 'rxjs/internal/observable/innerFrom'
import { ToastService } from '../toast/toast.service'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { PersonalInfoComponent } from '../../pages/onboarding/personal-info/personal-info.component'
import { referrerCodeFromUrl } from '../../shared/functions/referrer-code-from-url.function'
import { MarketingUrl } from 'cashli-capacitor-marketing-url'
import { ModalController } from '@ionic/angular'
import { ForceUpdateComponent } from '../../shared/components/force-update/force-update.component'

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {
  packageVersionCode!: string
  currentVersionCode!: string | undefined
  availableVersionCode!: string | undefined
  backEndVersion: string | undefined

  minor = 5
  major = 10

  constructor (private http: HttpClient,
               private modalCtrl: ModalController,
               private toast: ToastService) {
    this.packageVersionCode = environment.version.replace(/\./g, '')
  }

  update () {
    AppUpdate.openAppStore().then()
  }

  getBackendVersion () {
    return this.http.get<{version: string}>(environment.backendEndpoint + '/version')
    .pipe(tap(v => this.backEndVersion = v.version))
  }

  releaseDiff() {
    return this.availableVersionCode && this.currentVersionCode
      ? Number(this.availableVersionCode) - Number(this.currentVersionCode)
      : 0
  }

  getAppVersion () {
    return fromPromise( AppUpdate.getAppUpdateInfo())
    .pipe(
      tap(r => {
        this.currentVersionCode = r.currentVersionCode
        this.availableVersionCode = r.availableVersionCode
        if (this.releaseDiff() >= this.major) {
          this.updateDialog().then()
        }
      }),
      catchError(err => {
        if (Capacitor.isNativePlatform()) {
          this.toast.error(JSON.stringify(err)).then()
          this.currentVersionCode = undefined
          this.availableVersionCode = undefined
          console.debug('getAppVersion', err)
        } else {
          this.currentVersionCode = this.packageVersionCode
          this.availableVersionCode = `${Number(this.packageVersionCode)}`
          if (this.releaseDiff() >= this.major) {
            this.updateDialog().then()
          }
        }
        return of({})
      }))
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return of(true)
  }

  async updateDialog () {
    const modal = await this.modalCtrl.create({
      component: ForceUpdateComponent,
      componentProps: {},
      backdropDismiss: false,
      cssClass: 'bottom-form'
      //initialBreakpoint: 0.6,
      //breakpoints: [0.6]
    });
    await modal.present()
  }
}
