import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs'
import { Bank, BankTransaction, TransactionKind, TransactionStatus } from '../../model/bank.model'
import { TrafficSource, TrafficSourceRequisites } from '../../model/traffic-source.model'
import moment from 'moment/moment'

@Injectable({
  providedIn: 'root'
})
export class BankService {
  data$!: BehaviorSubject<Bank>
  ts$!: BehaviorSubject<TrafficSource>
  transactions!: BankTransaction[]
  payout!: BankTransaction[]
  earnings!: BankTransaction[]
  expectedPayments!: BankTransaction[]
  pendingEarnings!: number
  pendingWithdraw!: number

  constructor (private http: HttpClient) {

  }

  list () {
    return this.http.get<Bank>(environment.backendEndpoint + '/bank')
    .pipe(
      tap(v => this.data$ ? this.data$.next(v) : this.data$ = new BehaviorSubject(v)),
      tap(() => {
        this.transactions = this.getTransactions()
        this.payout = this.getPayout()
        this.earnings = this.getEarnings()
        this.expectedPayments = this.getExpectedPayments()
        this.pendingEarnings = this.getPendingEarnings()
        this.pendingWithdraw = this.getPendingWithdraw()
      })
    )
  }

  trafficSource () {
    return this.http.get<TrafficSource>(environment.backendEndpoint + '/trafficSource')
    .pipe(tap(v => this.ts$ ? this.ts$.next(v) : this.ts$ = new BehaviorSubject(v)))
  }

  postRequisites (data: TrafficSourceRequisites) {
    return this.http.post<TrafficSourceRequisites>(environment.backendEndpoint + '/requisites', data)
    .pipe(tap(() => this.trafficSource().subscribe()))
  }

  deleteRequisites () {
    return this.http.delete<TrafficSourceRequisites>(environment.backendEndpoint + '/requisites')
    .pipe(tap(() => this.trafficSource().subscribe()))
  }

  requestWithdraw () {
    return this.http.post<TrafficSourceRequisites>(environment.backendEndpoint + '/withdrawal', {})
    .pipe(tap(() => this.trafficSource().subscribe()), tap(() => this.list().subscribe()))
  }

  getTransactions () {
    return this.data$.value && this.data$.value.transactions
    .filter(t => t.executed || t.planned || t.created)
    .sort((a, b) =>
      moment(a.executed || a.planned || a.created).unix() >
      moment(b.executed || b.planned || b.created).unix() ? -1 : 1)
  }

  getPayout () {
    return this.data$.value && this.transactions.filter(t => t.kind === TransactionKind.WITHDRAWAL)
  }

  getPendingWithdraw () {
    const amount = this.payout?.filter(t => t.status === TransactionStatus.PENDING)
    .reduce((sum, t) => sum = sum + t.amount, 0)
    return amount ? amount * -1 : amount || 0
  }

  getPendingEarnings () {
    return  this.expectedPayments
    .reduce((sum, t) => sum = sum + t.amount, 0)
  }

  getEarnings () {
    return this.transactions
    .filter(t => (t.kind === TransactionKind.PAYMENT && t.executed) ||
      t.kind === TransactionKind.REFERRAL_BONUS ||
      t.kind === TransactionKind.RECALCULATION ||
      t.kind === TransactionKind.BONUS)
  }

  getExpectedPayments () {
    return this.transactions
    .filter(t => t.kind === TransactionKind.PAYMENT && !t.executed)
    //e.sort((a,b) => moment(a.executed).isAfter(b.executed) ? 1 : -1)
  }

  groupByDate (array: BankTransaction[], maxSize?: number): Map<number, BankTransaction[]> {
    let res = array.reduce((store, item) => {
      const key = moment(item.executed || item.planned || item.created).startOf('day').unix()
      if (!store.has(key)) {
        store.set(key, [item])
      } else {
        store.get(key)?.push(item)
      }
      return store
    }, new Map<number, BankTransaction[]>)
    if (maxSize) {
      let arr = Array.from(res).slice(0, maxSize)
      return new Map(arr)
    } else {
      return res
    }
  }

  get isWithdrawalEnable() {
    return (this.data$?.value?.availableAmount >= 0 &&
        (this.data$?.value?.availableAmount >= this.data$?.value?.minWithdrawalAmount) &&
      this.ts$?.value?.requisites?.accountNumber)
  }

  clear () {
    delete (this as any).data$
    delete (this as any).ts$
  }

}
